import React from "react";

function Footer() {
  return (
    <footer className="footer-area">
        <div className="footer-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="footer-subscribe d-block d-lg-flex justify-content-between align-items-center">
                            <div className="subscribe-content">
                                <span>Unlock Content, Stay Up to Date, and Fuel Your Curiosity.</span>
                                <h3 className="title">Subscribe to Our Newsletter Today!</h3>
                            </div>
                            <div className="subscribe-input">
                                <form action="#">
                                    <div className="input-box">
                                        <input type="text" placeholder="Enter your email address"/>
                                        <button className="main-btn">Subscribe Now</button>
                                        <i className="fal fa-envelope"></i>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-item">
                    <div className="back-to-top">
                        <i className="fal fa-long-arrow-up"></i>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="footer-about mt-30">
                                <h3 className="title">About Us</h3>
                                <p>Core Digitals Empower UK businesses with dynamic digital strategies, our digital marketing company harnesses 
                                    the power of data-driven insights and creative expertise to optimise brand visibility, engage target audiences,
                                    and drive measurable growth. Our tailored solutions and innovative approach ensure that businesses thrive in the 
                                    ever-evolving digital landscape, achieving remarkable success in the UK market.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-5 col-sm-7">
                            <div className="footer-services mt-30">
                                <div className="footer-services-title">
                                    <h3 className="title">Quick Links</h3>
                                </div>
                                <div className="footer-services-list">
                                    <ul>
                                        <li><a href="index.php">Home</a></li>
                                        <li><a href="about.php">About</a></li>
                                        <li><a href="portfolio.php">Portfolio</a></li>
                                        <li><a href="faq.php">FAQ</a></li>
                                        <li><a href="contact.php">Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-7">
                            <div className="footer-services mt-30">
                                <div className="footer-services-title">
                                    <h3 className="title">Our Services</h3>
                                </div>
                                <div className="footer-services-list">
                                    <ul>
                                        <li><a href="seo-service-details.php">SEO Service</a></li>
                                        <li><a href="mobile-app-design.php">Mobile App Design</a></li>
                                        <li><a href="website-uix-design.php">Website UIX Design</a></li>
                                        <li><a href="game-design.php">Game Design</a></li>
                                        <li><a href="web-application-design.php">Web Application Design</a></li>
                                        <li><a href="ssm-services.php">SMM Services</a></li>
                                    </ul>
                                    <ul className="ml-35">
                                        <li><a href="startup-business.php">Startup Business</a></li>
                                        <li><a href="home-delivery-app.php">Home Delivery App</a></li>
                                        <li><a href="ride-sharing-business.php">Ride Sharing Business</a></li>
                                        <li><a href="pro-app-growing.php">Pro App Growing</a></li>
                                        <li><a href="transport-business-grow.php">Transport Business Grow </a></li>
                                        <li><a href="brand-new-business.php">Brand New Business</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-copyright">
            <div className="container">
                <div className="col-lg-12">
                    <div className="copyright-item d-block d-lg-flex justify-content-between align-items-center">
                        <div className="copyright-logo">
                            <a href="#"><img src="assets/images/logo-2.png" alt=""/></a>
                        </div>
                        <div className="footerlinks">
                            <ul>
                                <li><a href="terms-condition.php">Terms & Condition</a></li>
                                <li><a href="privacy-policy.php">Privacy Policy</a></li>
                            </ul>
                        </div>
                        <div className="copyright-content">
                            <p>Copyright <a href="#"> Core Digitals</a> - 2023</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
}
export default Footer;