import { Link } from 'react-router-dom';
import React from 'react'


export default function Header() {
  return (
    <header className="header-area">
        <div className="container">
            <div className="row">
            <div className="col-lg-12">
                <div className="navigation">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <Link className="navbar-brand" to="/"><img src="assets/images/logo.png" alt=""/></Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    </button> 
                    <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto">
                        <li className="nav-item active">
                        <Link className="nav-link" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                        <Link className="nav-link" to="/About">About</Link>
                        </li>
                        <li className="nav-item">
                        <Link className="nav-link" to="portfolio.php">Portfolio</Link>
                        </li>
                        <li className="nav-item">
                        <Link className="nav-link" to="#">Services <i className="fal fa-angle-down"></i></Link>
                        <ul className="sub-menu">
                            <li><Link to="seo-service-details.php">SEO Service</Link></li>
                            <li><Link to="mobile-app-design.php">Mobile App Design</Link></li>
                            <li><Link to="website-uix-design.php">Website UIX Design</Link></li>
                            <li><Link to="game-design.php">Game Design</Link></li>
                            <li><Link to="web-application-design.php">Web Application Design</Link></li>
                            <li><Link to="ssm-services.php">SMM Services</Link></li>
                            <li><Link to="startup-business.php">Startup Business</Link></li>
                            <li><Link to="home-delivery-app.php">Home Delivery App</Link></li>
                            <li><Link to="ride-sharing-business.php">Ride Sharing Business</Link></li>
                            <li><Link to="pro-app-growing.php">Pro App Growing</Link></li>
                            <li><Link to="transport-business-grow.php">Transport Business Grow </Link></li>
                            <li><Link to="brand-new-business.php">Brand New Business</Link></li>
                        </ul>
                        </li>
                        <li className="nav-item">
                        <Link className="nav-link" to="contact.php">Contact</Link>
                        </li>
                    </ul>
                    </div>
                
                    <div className="navbar-btn d-none d-sm-flex align-items-center">
                    <Link className="main-btn" to="contact.php">Get A Quote</Link>
                    </div>
                </nav>
                </div>
                
            </div>
            </div>
        
        </div>
    </header>
  )
}
