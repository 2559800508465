import React from 'react'
import { Link } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

export default function About() {
  return (
    <>
    {/* HEADER SECTION */}
    <Header/>

    {/* ABOUT US BANNER */}
    <section className="page-title-area mt-120">
        <div className="container">
            <div className="row">
                <div className="col-lg-5">
                    <div className="page-title">
                        <h3 className="title">Who We Are</h3>
                        <p>A Digital Marketing Partner You Can Rely On</p>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">About us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="page-title-thumb">
                        <img src="assets/images/page-thumb.png" alt="page-thumb"/>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!--====== ABOUT EXPERIENCE PART START ======--> */}

<section className="about-experience-area">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-10">
                <div className="about-experience-content text-center">
                    <i className="fal fa-chart-pie-alt"></i>
                    <h3 className="title">Years of Experience in Driving Marketing Excellence</h3>
                    <p>Core Digitals is an outstanding digital marketing firm that provides a comprehensive range of data-driven Internet marketing services.
                        We create online marketing campaigns with a growth-oriented focus that positively affects businesses.</p>
                </div>
            </div>
        </div> 
        <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6">
                <div className="experience-item text-center">
                    <i className="fal fa-book"></i>
                    <p>Good pricing</p>
                </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
                <div className="experience-item text-center mt-80">
                    <i className="fal fa-chart-line"></i>
                    <p>24/7 customer support</p>
                </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
                <div className="experience-item text-center mt-140">
                    <i className="fal fa-chalkboard"></i>
                    <p>Easy to use</p>
                </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
                <div className="experience-item text-center mt-140">
                    <i className="fal fa-users"></i>
                    <p>Responsive</p>
                </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
                <div className="experience-item text-center mt-80">
                    <i className="fal fa-bullseye"></i>
                    <p>Reliable</p>
                </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
                <div className="experience-item text-center">
                    <i className="fal fa-headphones-alt"></i>
                    <p>Flexible</p>
                </div>
            </div>
        </div>
    </div>
</section>

{/* <!--====== ABOUT EXPERIENCE PART ENDS ======--> */}


{/* <!--====== ABOUT COUNTER PART START ======--> */}

<section className="about-counter-area pt-140">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="counter-thumb text-center">
                    <img src="assets/images/about-thumb-2.png" alt=""/>
                </div>
            </div>
        </div> 
        <div className="about-counter-bg">
            <div className="row justify-content-center">
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="about-counter-item mt-30">
                        <i className="fal fa-desktop"></i>
                        <h1 className="title"><span className="counter">5000</span>+</h1>
                        <span>Project Done</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="about-counter-item mt-30">
                        <i className="fal fa-users"></i>
                        <h1 className="title"><span className="counter">100</span>+</h1>
                        <span>Active Clients</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="about-counter-item mt-30">
                        <i className="fal fa-award"></i>
                        <h1 className="title"><span className="counter">22</span>+</h1>
                        <span>Get Rewards</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="about-counter-item mt-30">
                        <i className="fal fa-coffee"></i>
                        <h1 className="title"><span><span className="counter">10</span>k</span>+</h1>
                        <span>Cup Of Coffee</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

{/* <!--====== ABOUT COUNTER PART ENDS ======--> */}



    {/* FOOTER SECTION */}
    <Footer/>
    </>
  )
}



