import Header from "../Components/Header";
import Footer from "../Components/Footer";


function Home() {
  return (
    <>
    <Header/>

    <div className='display'>
      <div className='container'>
          <div className='container-inner'>
              <div className='bars'>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
                  <div className='bar'></div>
              </div>
          </div>
      </div>
  </div>
  
  <section className="banner-area mt-125 d-flex align-items-center">
    <div className="container">
        <div className="row">
            <div className="col-lg-6 col-md-8">
                <div className="banner-content">
                    <div className="vector-body">
                        <img className="img-fluid vec-4" src="assets/images/custom_Vectors/abstract-blob-2.png" alt="blob"/>
                        <img className="img-fluid vec-5" src="assets/images/custom_Vectors/circle.png" alt="Circle"/>
                        <img className="img-fluid vec-6" src="assets/images/custom_Vectors/butterfly.png" alt="butter"/>
                    </div>
                    <span>Core Digitals - A Hub of Trusted Digital Marketers</span>
                    <h1 className="title">Hire Expert Digital Marketing Services in UK</h1>
                    <p>Core Digitals offer digital marketing services that increase brand awareness, boost lead generation,
                        elevate sales, improve customer service, and deliver measurable results to eradicate your most difficult internet marketing obstacles.</p>
                    <img className="img-fluid vec-1" src="assets/images/custom_Vectors/Abstract-1.png" alt="break blob"/>
                    <img className="img-fluid vec-2" src="assets/images/custom_Vectors/Ellipse.png" alt="break blob"/>
                    <img className="img-fluid vec-3" src="assets/images/custom_Vectors/abstract-blob-1.png" alt="blob"/>
                </div> 
            </div>
        </div> 
       
    </div>
    <div className="banner-thumb">
        <img src="assets/images/banner-thumb.png" alt=""/>
    </div>
  </section>

  <section className="services-area pb-140">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="section-title text-center">
                    <span>Our Services</span>
                    <h3 className="title">Your One-Stop Shop for Digital Marketing</h3>
                    <img src="assets/images/title-shape.png" alt="shape"/>
                </div>
            </div>
        </div> 
        <div className="row justify-content-center">
            <div className="col-lg-4 col-md-7 col-sm-9">
                <div className="services-item text-center mt-30">
                    <img src="assets/images/icon/icon-1.png" alt="icon"/>
                    <h4 className="title">Variety of Digital <br/>UI/UX Products</h4>
                    <div className="list d-flex justify-content-center">
                        <ul className="text-left">
                            <li><a href="#"><i className="fal fa-plus"></i> Web Application Design</a></li>
                            <li><a href="#"><i className="fal fa-plus"></i> Websites Design</a></li>
                            <li><a href="#"><i className="fal fa-plus"></i> Mobile Apps Design</a></li>
                            <li><a href="#"><i className="fal fa-plus"></i> Game Design</a></li>
                        </ul>
                    </div>
                </div> 
            </div>
            <div className="col-lg-4 col-md-7 col-sm-9">
                <div className="services-item center text-center mt-30">
                    <div className="services-dot">
                        <img src="assets/images/icon/services-dot.png" alt=""/>
                    </div>
                    <img src="assets/images/icon/icon-2.png" alt="icon"/>
                    <h4 className="title">We Help Products <br/> and Businesses Thrive</h4>
                    <div className="list d-flex justify-content-center">
                        <ul className="text-left">
                            <li><a href="#"><i className="fal fa-plus"></i> Startup Business </a></li>
                            <li><a href="#"><i className="fal fa-plus"></i> Home Delivery App</a></li>
                            <li><a href="#"><i className="fal fa-plus"></i> Ride Sharing Business</a></li>
                            <li><a href="#"><i className="fal fa-plus"></i> Business Consultant</a></li>
                        </ul>
                    </div>
                </div> 
            </div>
            <div className="col-lg-4 col-md-7 col-sm-9">
                <div className="services-item text-center mt-30">
                    <img src="assets/images/icon/icon-3.png" alt="icon"/>
                    <h4 className="title">Digital Strategy and Core Consultation</h4>
                    <div className="list d-flex justify-content-center">
                        <ul className="text-left">
                            <li><a href="#"><i className="fal fa-plus"></i> SMM Services</a></li>
                            <li><a href="#"><i className="fal fa-plus"></i> SEO Services</a></li>
                            <li><a href="#"><i className="fal fa-plus"></i> Pro App Growing</a></li>
                            <li><a href="#"><i className="fal fa-plus"></i> Transport Business Grow </a></li>
                        </ul>
                    </div>
                </div> 
            </div>
        </div> 
    </div>
</section>

<div className="query-area mb-130">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="animate-vec">
                    <img className="img-fluid vec-8" src="assets/images/custom_Vectors/abstract-blob-3.png"/>
                    <img className="img-fluid vec-9" src="assets/images/custom_Vectors/Abstract-2.png"/>
                    <img className="img-fluid vec-10" src="assets/images/custom_Vectors/Ellipse.png"/>
                </div>
                <div className="query-item d-block d-lg-flex justify-content-between align-items-center">
                    <div className="dot-1">
                        <img src="assets/images/icon/dot-1.png" alt=""/>
                    </div>
                    <div className="dot-2">
                        <img src="assets/images/icon/dot-2.png" alt=""/>
                    </div>
                    <div className="query-content">
                        <span>Are You Tired of Your Website Not Getting Any Traffic?</span>
                        <h3 className="title">Book a Free Consultation Now.</h3>
                    </div>
                    <div className="query-btn">
                        <ul className=" d-block d-sm-flex">
                            <li><a className="main-btn" href="tel:08000584425"><i className="fal fa-phone"></i> Call Us Now</a>
                            </li>
                            <li><a className="main-btn main-btn-2" href="contact.php">Contact Us</a></li>
                        </ul>
                    </div>
                </div> 
                <div className="animate-vec">
                    <img className="img-fluid vec-7" src="assets/images/custom_Vectors/butterfly-2.png"/>
                    <img className="img-fluid vec-11" src="assets/images/custom_Vectors/abstract-blob-2.png"/>
                    <img className="img-fluid vec-12" src="assets/images/custom_Vectors/circle.png"/>
                </div>
            </div>
        </div> 
    </div>
</div>

<section className="about-us-area mb-140">
    <div className="container">
        <div className="row justify-content-lg-end justify-content-start">
            <div className="col-lg-6 col-md-10">
                <div className="about-us-content">
                    <span>Better Digital Presence, Better clientele</span>
                    <h3 className="title">Skyrocket ROI by 35% with No.1 Digital Marketing Services</h3>
                    <img src="assets/images/title-shape.png" alt=""/>
                    <p>Our professional digital marketing services leverage the synergy between exceptional talent and cutting-edge technology to maximise
                        your marketing return on investment. With a team of over 500+ digital marketing experts, we have consistently enabled our clients
                        to achieve an average increase of 35% in their marketing ROI.</p>
                </div>
                <div className="about-mission-vision">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="about-mission mt-30">
                                <div className="icon">
                                    <img src="assets/images/icon/about-icon-1.png" alt=""/>
                                </div>
                                <h4 className="title">Our Mission</h4>
                                <p>We are committed to empowering brands with unmatched digital growth strategies and results.</p>
                            </div> 
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="about-mission about-vision mt-30">
                                <div className="icon">
                                    <img src="assets/images/icon/about-icon-2.png" alt=""/>
                                </div>
                                <h4 className="title">Our Vision</h4>
                                <p>Core Digitals is paving the way for digital marketing excellence and establishing new industry standards.</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
    <div className="about-thumb">
        <img src="assets/images/about-thumb.png" alt="about"/>
    </div>
    <div className="animate-vec">
        <img className="img-fluid vec-7" src="assets/images/custom_Vectors/butterfly-2.png"/>
        <img className="img-fluid vec-11" src="assets/images/custom_Vectors/abstract-blob-2.png"/>
        <img className="img-fluid vec-12" src="assets/images/custom_Vectors/circle.png"/>
    </div>
</section>

<div className="skill-area mb-140">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-6">
                <div className="skills">
                    <div className="animate-vec">
                        <img className="img-fluid vec-9" src="assets/images/custom_Vectors/Abstract-1.png"/>
                    </div>
                    <div className="skill-item">
                        <div className="skill-header">
                            <h3 className="skill-title">Productivity</h3>
                            <div className="skill-percentage">
                                <div className="count-box"><span className="count-text" data-speed="2100" data-stop="65">0</span>%</div>
                            </div>
                        </div>
                        <div className="skill-bar">
                            <div className="bar-inner">
                                <div className="bar progress-line" data-width="65"></div>
                            </div>
                        </div>
                    </div>

                    <div className="skill-item">
                        <div className="skill-header">
                            <h3 className="skill-title">Wireframe</h3>
                            <div className="skill-percentage">
                                <div className="count-box"><span className="count-text" data-speed="2000" data-stop="80">0</span>%</div>
                            </div>
                        </div>
                        <div className="skill-bar">
                            <div className="bar-inner">
                                <div className="bar progress-line" data-width="80"></div>
                            </div>
                        </div>
                    </div>

                    <div className="skill-item">
                        <div className="skill-header">
                            <h3 className="skill-title">Work Output</h3>
                            <div className="skill-percentage">
                                <div className="count-box"><span className="count-text" data-speed="1900" data-stop="72">0</span>%</div>
                            </div>
                        </div>
                        <div className="skill-bar">
                            <div className="bar-inner">
                                <div className="bar progress-line" data-width="72"></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="col-lg-6">
                <div className="skill-brand ml-70 mr-50">
                    <div className="icon">
                        <i className="fal fa-calendar-alt"></i>
                    </div>
                    <div className="brand-list d-flex justify-content-between">
                        <div className="brand-item">
                            <a href="#"><img src="assets/images/brand-1.png" alt="brand"/></a>
                        </div>
                        <div className="brand-item">
                            <a href="#"><img src="assets/images/brand-2.png" alt="brand"/></a>
                        </div>
                    </div>
                    <div className="brand-list d-flex justify-content-between mt-80">
                        <div className="brand-item">
                            <a href="#"><img src="assets/images/brand-3.png" alt="brand"/></a>
                        </div>
                        <div className="brand-item">
                            <a href="#"><img src="assets/images/brand-4.png" alt="brand"/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</div>

<div className="portfolio-2-area pt-110 pb-140">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="portfolio-btns">
                    <div className="project-menu">
                        <ul>
                            <li data-filter="*" className="active">All Works</li>
                            <li data-filter=".web-design">Website Design</li>
                            <li data-filter=".game-design">Game Design</li>
                            <li data-filter=".brandings">Branding</li>
                            <li data-filter=".mobile-app">Mobile App</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="row grid">
                    <div className="col-lg-4 col-md-4 col-sm-6 web-design">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/WebDesign/webdesign_1.webp" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/WebDesign/webdesign_1.webp"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 web-design">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/WebDesign/webdesign_2.webp" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/WebDesign/webdesign_2.webp"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 web-design">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/WebDesign/webdesign_3.webp" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/WebDesign/webdesign_3.webp"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 web-design">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/WebDesign/webdesign_4.webp" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/WebDesign/webdesign_4.webp"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 web-design">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/WebDesign/webdesign_5.webp" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/WebDesign/webdesign_5.webp"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 web-design">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/WebDesign/webdesign_6.webp" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/WebDesign/webdesign_6.webp"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 web-design">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/WebDesign/webdesign_7.webp" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/WebDesign/webdesign_7.webp"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 web-design">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/WebDesign/webdesign_8.webp" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/WebDesign/webdesign_8.webp"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 web-design">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/WebDesign/webdesign_9.webp" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/WebDesign/webdesign_9.webp"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 game-design">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/GameDesign/1.jpg" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/GameDesign/1.jpg"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-4 col-md-4 col-sm-6 game-design">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/GameDesign/2.jpg" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/GameDesign/2.jpg"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 game-design">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/GameDesign/3.jpg" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/GameDesign/3.jpg"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-4 col-sm-6 game-design">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/GameDesign/4.jpg" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/GameDesign/4.jpg"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 game-design">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/GameDesign/5.jpg" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/GameDesign/5.jpg"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 game-design">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/GameDesign/6.jpg" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/GameDesign/6.jpg"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 brandings">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/Branding/b-1.jpg" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/Branding/b-1.jpg"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 brandings">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/Branding/b-2.jpg" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/Branding/b-2.jpg"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 brandings">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/Branding/b-3.jpg" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/Branding/b-3.jpg"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 brandings">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/Branding/b-4.jpg" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/Branding/b-4.jpg"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 brandings">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/Branding/b-5.jpg" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/Branding/b-5.jpg"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 brandings">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/Branding/b-6.jpg" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/Branding/b-6.jpg"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 brandings">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/Branding/b-7.jpg" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/Branding/b-7.jpg"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 brandings">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/Branding/b-8.jpg" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/Branding/b-8.jpg"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 brandings">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/Branding/b-9.jpg" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/Branding/b-9.jpg"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 brandings">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/Branding/b-10.jpg" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/Branding/b-10.jpg"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 brandings">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/Branding/b-11.jpg" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/Branding/b-11.jpg"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 brandings">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/Branding/b-12.jpg" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/Branding/b-12.jpg"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 mobile-app">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/MobileApp/mobile_app_1.png" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/MobileApp/mobile_app_1.png"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 mobile-app">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/MobileApp/mobile_app_2.png" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/MobileApp/mobile_app_2.png"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 mobile-app">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/MobileApp/mobile_app_3.png" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/MobileApp/mobile_app_3.png"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 mobile-app">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/MobileApp/mobile_app_4.png" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/MobileApp/mobile_app_4.png"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 mobile-app">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/MobileApp/mobile_app_5.png" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/MobileApp/mobile_app_5.png"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 mobile-app">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/MobileApp/mobile_app_6.png" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/MobileApp/mobile_app_6.png"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 mobile-app">
                        <div className="portfolio-item mt-30">
                            <img src="assets/images/portfolio/MobileApp/mobile_app_7.png" alt="portfolio"/>
                            <div className="portfolio-overlay">
                                <a className="main-btn image-popup" href="assets/images/portfolio/MobileApp/mobile_app_7.png"><i className="fal fa-search"></i> View Work</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div> 
    </div>
</div>

    <Footer/>
  </>
  
  );
}

export default Home;
